/* Base Styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Tailwind CSS Directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Base Layer Styles */
@layer base {
  /* Paragraph styles */
  html{
    overflow-x: hidden
  }
  p {
    @apply font-semibold;
    @apply tracking-wide;
    @apply leading-normal;
  }

  /* Heading styles */
  h1, h2, h3, h4, h5 {
    @apply font-bold;
    @apply text-xl;
  }

  /* List item styles */
  li {
    @apply font-semibold;
  }

  /* Footer list item hover effect */
  footer li {
    @apply hover:text-[#1291b1];
  }

  /* Anchor tag hover effect */
  a {
    @apply hover:text-[#1291b1];
  }
}

/* React Calendar Custom Styles */
.react-calendar {
  width: auto !important;
  border: none !important;
  margin: 1rem !important;
}

@media (min-width:430px) {
  .react-calendar__month-view__days{
    height: 16rem;
  }

  .react-calendar {
    margin: 2rem !important;
  }
}

@media (min-width:550px) {
  .react-calendar__month-view__days{
    height: 20rem;
  }

  .react-calendar {
    margin: 3rem !important;
    max-width: 500px !important;
  }
}


/* Custom Styles for Dates */
.booked-date {
  background-color: #ff6347; /* Tomato red for booked dates */
  color: black;
}

.open-date {
  background-color: mediumseagreen;
  color: black;
}

/* React Calendar Tile Custom Styles */
.react-calendar__tile .booked-date,
.react-calendar__tile .open-date {
  width: 100%;
  height: 25%;
  position: relative;
  border-radius: 5px; /* Rounded corners */
}

/* Weekend Day Color Override */
.react-calendar__month-view__days__day--weekend {
  color: black !important;
}

/* Link Styles */
a{
  text-decoration: none !important;
}

.booking-cta{
 color: #232323;
  text-decoration: none;
}

.header-link.active {
  color: #1291b1 !important;
}

.homenav{
  color: white;
}

.othernav{
  color: black;
}

.homenav.scrolled{
  color: black;
}
/* List Styles */
ul:not(.footer-ul) {
  padding-left: 0 !important;
}

ul {
  margin-bottom: 0 !important;
}

/* React Tabs Custom Styles */
.react-tabs{
  max-width: 1024px;
  margin: auto;
}
.react-tabs__tab {
  border: none !important; 
  
}

.react-tabs__tab-list {
  border: none !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: center !important;
}

.react-tabs__tab--selected {
  color: #1291b1 !important;

}

@media(min-width: 700px){
  .react-tabs__tab--selected {
    border-color: #aaa;
    border-radius: 0 !important;
    font-weight: bold;
  }

  .react-tabs__tab {
    border: 1px solid #aaa!important;
    border-bottom: none;
    
  }
  .react-tabs__tab-list {
    border-bottom: 1px solid #aaa !important;
  }
  
}

/* Skip Link Styles */
.skip-link {
  position: absolute;
  top: -40px;
  left: 0;
  background: #000;
  color: white;
  padding: 8px;
  z-index: 100;
}

.skip-link:focus {
  top: 0;
}

/* Button Hover Effect */
.form-button:hover {
  color: #1291b1 !important;
}

.other-feature::marker{
  content: '⇒ ';
  color: #1291b1;
  font-weight: bold;
}
